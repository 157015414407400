
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.domain {
    color: #0d5356;
    word-wrap: break-word;
    margin-left: 6px;
}
.whyUsIcons {
    border: none;
    @include md {
        border-right: 1px solid;
        border-right-color: #0d5356;
    }
}
.whyUsIcons:last-child {
    border: none;
}
.paragraphColor {
    color: #505050 !important;
}

.wrapper {
    max-width: 900px;
}
